<template>
    <div>
        <custom-alert
          v-if="displayAlert == true"
          :message="alertMessage"
          id="alert_box"
          v-on:showedAlert="resetAlert"
        ></custom-alert>
            <span class="text-white" style="font-size:14px;font-weight:0">
            <b-form-checkbox
            v-model="autoRenew"
            name="autorenewal"
            switch
            value="1"
            size="lg"
            :checked="autoRenew"
            @change="changeAutoRenewal($event)"
            > <p v-if="page!='setting'" :class="page=='dashboard'? 'dashboard-text-color':''">Auto Renewal</p></b-form-checkbox></span>
                               
    <b-modal id="autoRenewalModal" ref="modal" hide-footer centered title="Enable Auto Renewal"
                 @show="resetCardModal" @hidden="resetCardModal" @cancel="resetRenewalStatus">
            <template v-if="getSiteProps('general.paymentGateway') == 'stripe'">
                <div class="col-md-12">
                    <div class="form-group mb-0">
                        <label class="form-label mb-2">Card Number / Expiry Month and Year / CVC<sup class="text-danger">*</sup></label>
                        <stripe-element-card 
                            v-if="autoRenewalModalOpen"
                            ref="stripeForm"
                            :pk="stripeKey"
                            :hidePostalCode="true"
                            iconStyle="solid"
                            @token="stripeTokenCreated"
                            @error="captureStripeErrors"
                            @element-change="stripeFormChanges"
                        />
                        <p class="text-sm text-danger text-center" v-if="stripeError">{{ stripeError }}</p>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="row">
                    <div  class="col-md-12">
                        <div  class="form-group">
                            <label class="form-label" >Card Number<sup  class="text-danger">*</sup></label>
                            <input  type="text" maxlength="16" placeholder="1234 1234 1234 1234" v-model="UpdCardInfo.cardNumber" class="form-control">
                            <small class="text-danger" v-html="cardError.cardNumber"></small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div  class="col-md-4">
                        <label class="form-label">Expiration Date<sup  class="text-danger">*</sup></label>
                        <select id="expiration-month" required="required" v-model="UpdCardInfo.expiration_month" class="form-control form-select">
                            <option value="">Month</option>
                            <option >01</option>
                            <option >02</option>
                            <option >03</option>
                            <option >04</option>
                            <option >05</option>
                            <option >06</option>
                            <option >07</option>
                            <option >08</option>
                            <option >09</option>
                            <option >10</option>
                            <option >11</option>
                            <option >12</option>
                        </select>
                        <small class="text-danger" v-html="cardError.expiration_month"></small>
                    </div>
                    <div  class="col-md-4 pl-0 col-md-offset-1" style="margin-top: 28px;">
                        <select id="expiration-year" required="required" v-model="UpdCardInfo.expiration_year" class="form-select form-control">
                            <option value="">Year</option>
                            <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                        </select>
                        <small class="text-danger" v-html="cardError.expiration_year"></small>
                    </div>
                    <div  class="col-md-4">
                        <label class="form-label">CVV/CVC<sup  class="text-danger">*</sup></label>
                        <input maxlength="4" type="password" v-model="UpdCardInfo.cvv" class="form-control">
                        <small class="text-danger" v-html="cardError.cvv"></small>
                    </div>
                </div>
            </template>

            <div class="modal-footer">
                <button type="button" class="btn btn-primary" @click="updateCreditCardInfo" :disabled="autoRenewProgress">
                    Enable <i class="fa fa-circle-notch fa-spin" v-if="autoRenewProgress"></i>
                </button>
            </div>

    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import CustomAlert from "../../CustomAlert";
import $ from "jquery";
export default {
    name: "AutoRenewalButton",
    components: { CustomAlert,StripeElementCard },
    data(){
        return{
            UpdCardInfo:{
                stripeToken: "",
                cardNumber: "",
                expiration_year:"",
                expiration_month:"",
                cvv:""
            },
            stripeError: "",
            cardError: {},
            autoRenewProgress:false,
            autoRenewalModalOpen: false,
            alertMessage: "",
            displayAlert: "",
        }
    },
    props: {
        profileData: Array,
        autoRenew:Boolean,
        autoRenewData: Array,
        page: String
  },
  methods: {
    loadMembersInfo(){
        this.$emit('loadMembersInfo');
    },
    changeAutoRenewal(val){
              if(![7,9,10].includes(this.profileData.info.membership_status)){
                if(val) {
                    this.boxOne = '';
                    this.autoRenew ='';
                  const messageVNode1 = this.$createElement('h6', { class: 'mb-2' }, 'Are you sure you want to enable the Auto-Renewal?');
                    this.$bvModal.msgBoxConfirm([messageVNode1], {
                        noCloseOnBackdrop: true,
                        noCloseOnEsc: true,
                        size: "md",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitleHtml: "<i class='fa fa-check mr-2'></i> Yes, Enable It",
                        cancelTitle: "No, Leave it",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        hideHeader: true
                    })
                    .then((value) => {
                        this.boxOne = value
                        if(this.boxOne == false){
                            this.$bvModal.hide('autoRenewalModal');
                        }else if(this.boxOne == null){
                            this.$bvModal.hide('autoRenewalModal');
                        }else{
                            this.$nextTick(() => {
                                this.autoRenewProgress = false;
                                this.cardError = {};
                                this.stripeError = "";
                                this.$bvModal.show('autoRenewalModal');
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }else{
                    const messageVNode1 = this.$createElement('h6', { class: 'mb-2' }, 'Are you sure you want to cancel the Auto-Renewal?');
                    const messageVNode2 = this.$createElement('small', { class: 'text-danger' }, '*Card information will be removed as well');
                    this.$bvModal.msgBoxConfirm([messageVNode1, messageVNode2], {
                        size: 'md',
                        okVariant: "danger",
                        okTitleHtml: "<i class='fa fa-check mr-2'></i> Yes, Disable It",
                        cancelTitle: 'No, Leave it',
                        footerClass: 'p-2',
                        buttonSize: "sm",
                        hideHeader: true,
                        hideHeaderClose: false
                    })
                    .then(value => {
                        if(String(value)=="true") {
                            this.autoRenewData.status=0;
                            axios.post(this.basePath + 'api/member/auto-renew-status', this.autoRenewData,{ headers:this.memberHeaders })
                                .then(function (response) {
                                    this.loadMembersInfo();
                                    this.alertMessage = "Auto renewal disabled successfully";
                                    this.displayAlert = true;
                                }.bind(this))
                                .catch(function (error) {
                                    console.log(error);
                                });
                        }else{
                            this.autoRenew=this.profileData.info.auto_renew;
                        }
                    })
                    .catch(err => {
                        console.log(err);

                    })
                }
              }
              else{
                this.autoRenew=false;
                this.alertMessage = 'You are not allowed to do this action';
                this.displayAlert = true;
                this.setDangerBg();
              }
            },
    updateCardAPI(){
                if(this.profileData.info.registration[0].customerprofileid != null && this.profileData.info.registration[0].paymentprofileid != null){
                    this.UpdCardInfo.auto_renew = 1;
                    axios.post(this.basePath+"api/member/creditcard_info/"+this.profileData.info.registration[0].id, this.UpdCardInfo,{ headers:this.memberHeaders })
                    .then(function (response) {
                        if(response.data.status == "success"){
                            console.log("test2");
                            this.loadMembersInfo();
                            this.alertMessage = "Auto renewal enabled successfully";
                            this.displayAlert = true;
                            this.$bvModal.hide('autoRenewalModal');
                            this.autoRenewProgress = false;
                        } else {
                            this.cardError.cardNumber = "Invalid Card Number";
                            this.autoRenewProgress = false;
                        }
                    }.bind(this))
                    .catch(function (error) {
                        console.log(error);
                        this.autoRenewProgress = false;
                });
                }else{
                    this.createCreditCardInfo();
                }
                
            },
            createCreditCardInfo(){
                this.UpdCardInfo.auto_renew = 1;
                axios.post(this.basePath+"api/member/creditcard_info_create/"+this.profileData.info.registration[0].id, this.UpdCardInfo,{ headers:this.memberHeaders })
                    .then(function (response) {
                        if(response.data.status == "success"){
                            this.loadMembersInfo();
                            this.alertMessage = "Auto renewal enabled successfully";
                            this.displayAlert = true;
                            this.$bvModal.hide('autoRenewalModal');
                            this.autoRenewProgress = false;
                        } else {
                            this.cardError.cardNumber = "Invalid Card Number";
                            this.autoRenewProgress = false;
                        }
                    }.bind(this))
                    .catch(function (error) {
                        console.log(error);
                        this.autoRenewProgress = false;
                    });
            },
         
            updateCreditCardInfo(){
                if(this.$refs.stripeForm != undefined){
                    this.autoRenewProgress = true;

                    if(this.stripeValidate == false){
                        this.stripeError = "Please enter card details";
                        this.autoRenewProgress = false;
                        return;
                    }
                    console.log("test123");
                    this.$refs.stripeForm.submit();
                } else {
                    if(!this.validateCard()){
                        this.autoRenewProgress = true;
                        this.updateCardAPI();
                    }
                }
            },
            validateCard(){
                var error_flag = 0;
                this.cardError = {};
                if(this.UpdCardInfo.cardNumber == ""){
                    this.cardError.cardNumber = "Card Number Required";
                    error_flag++;
                }
                if(!/[^a-z.@]/i.test(this.UpdCardInfo.cardNumber)){
                    this.cardError.cardNumber = "Card Number is Invalid";
                    error_flag++;
                }
                if(this.UpdCardInfo.expiration_year == ""){
                    this.cardError.expiration_year = "Year Required";
                    error_flag++;
                }
                if(this.UpdCardInfo.expiration_month == ""){
                    this.cardError.expiration_month = "Month Required";
                    error_flag++;
                }
                if(this.UpdCardInfo.cvv == ""){
                    this.cardError.cvv = "CVV Required";
                    error_flag++;
                }

                if(error_flag == 0){
                    if(this.UpdCardInfo.expiration_year == new Date().getFullYear()){
                        if(this.UpdCardInfo.expiration_month < moment(new Date()).format("MM")){
                            this.cardError.cardNumber = "Card is Expired";
                            error_flag++;
                        }
                    } else if(this.UpdCardInfo.expiration_year < new Date().getFullYear()) {
                        this.cardError.cardNumber = "Card is Expired";
                        error_flag++;
                    }
                }

                return error_flag;
            },
            resetCardModal(){
                this.UpdCardInfo.stripeToken="";
                this.UpdCardInfo.cardNumber="";
                this.UpdCardInfo.expiration_year="";
                this.UpdCardInfo.expiration_month="";
                this.UpdCardInfo.cvv="";
                this.resetRenewalStatus();
            },
            resetRenewalStatus(){
                this.autoRenew=this.profileData.info.auto_renew;
            },
            stripeTokenCreated(res){
                this.stripeError = "";
                this.stripeToken = res.id;
                this.UpdCardInfo.stripeToken = this.stripeToken;
                
                this.updateCardAPI();
            },
            captureStripeErrors(errors){
                this.stripeError = errors.message != undefined ? errors.message : errors;
                this.autoRenewProgress = false;
            },
            stripeFormChanges(obj){
                this.stripeValidate = false;
                this.stripeError = "";
                if(obj.complete){
                    this.stripeValidate = true;
                }
            },
            getCardInformation(){
            axios.get(this.basePath+"api/member/creditcard_info/"+this.profileData.info.registration[0].id,{ headers:this.memberHeaders, params:{autorenewbutton:true} })
                .then((response)=>{
                    if(response.data.status == 'error'){
                        this.loadMembersInfo();
                    }
                }).catch((error) =>{
                console.log(error);
            });
        },
        setDangerBg(){
          setTimeout(() => {
            $('#alert_box').children('div').removeClass('alert-success');
            $('#alert_box').children('div').addClass('alert-danger');
          }, 100);
        },
        removeDangerBg(){
          setTimeout(() => {
            $('#alert_box').children('div').removeClass('alert-danger');
            $('#alert_box').children('div').addClass('alert-success');
          }, 100);
        },
    resetAlert(){
      this.displayAlert = false;
      this.removeDangerBg();
    },
  },
  mounted(){
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
            if(modalId == "autoRenewalModal"){
                this.autoRenewalModalOpen = true;
            }
        })
  },
  created(){
    this.getCardInformation();
  },
  computed : {
    years () {
            const year = new Date().getFullYear()
            return Array.from({length: 12}, (value, index) => year + index)
        }
  }
       
}

</script>
<style>
.dashboardrenewal .custom-control-label::before {
    background-color: #ffffff;
  } 
  .dashboardrenewal .custom-switch .custom-control-label::after {
    background-color: #143c6a;
  }
  .dashboard-text-color{
    color:#143c6a;
  }
</style>