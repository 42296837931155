<template>
  <div class="user-details">
    <!--header-->
    <div class="container-fluid">
      <div class="row mt-3 mb-3">
        <div class="col-sm-8">
          <h1 class="m-0 text-dark">{{ profileData.info.membership.name }} Profile</h1>
        </div>
        <div class="col-md-4">
          <div class="text-right">
            <ol class="breadcrumb float-sm-right p-0">
              <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
              <li class="breadcrumb-item"><Breadcrumbs/></li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="profile-header profile-bg">
      <div class="row">
        <div class="col-md-2 text-right hovereffect pl-5 pr-0 mr-0">
          <img style="height:100%;width:100%;" :src="profileImg" alt="User Avatar" :key="updateLoggedUserInfo" class="mr-3 avatar">
          <div class="overlay">
            <b-button class="badge text" v-b-modal.profile_pic_modal>
              <i class="fas fa-upload"></i> Upload Image
            </b-button>
          </div>
        </div>
        <div class="col-md-6">
          <div class="profiler-detail pl-4 pr-5 ">
            <h3>{{ profileData.info.campski.company || "N/A" }}<span class="text-danger text-capitalize family-cat-txt">{{ familyCat || "" }}</span></h3>
            <div class="d-flex">
              <div class="left mr-5">
                <h5 v-if="profileData.info.membership">{{ profileData.info.membership.name }} Membership</h5>
              </div>
              <div class="right" style="text-align: center; margin-bottom: 10px; padding: 8px;" v-show="digitalInfoView" v-if="(profileData.info.membership && profileData.info.membership.period != 999) && profileData.info.personal_details.relation == 0">
                <div class="autorenewal">
                  <AutoRenewalButton :page="page" :profileData="profileData" :autoRenew="autoRenew" :autoRenewData="autoRenewData" @loadMembersInfo="loadMembersInfo" />
                </div>
              </div>
            </div>
            <div class="b-der"></div>
            <div class="d-flex">
              <div class="reg_date mt-3 mr-3">
                <div class="d-flex align-items-center">
                  <div class="up_icon mr-4">
                    <img src="/dist/img/profile/ico-registerd-date.png">
                  </div>
                  <div class="up_text">
                    <span class="lable">Registered Date</span>
                    <span> {{ usdate(profileData.info.membership_startdate) || "N/A"}}</span>
                  </div>
                </div>

              </div>
              <div class="ex_date mt-3 ml-3">
                <div class="d-flex align-items-center">
                  <div class="up_icon mr-4">
                    <img src="/dist/img/profile/ico-expired-date.png">
                  </div>
                  <div class="up_text" v-if="profileData.info.membership">
                    <span  class="lable">Expiration Date</span>
                    <span> {{ profileData.info.membership.period != 999 ? usdate(profileData.info.membership_endtate) : "N/A"}}</span>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
        <div class="col-md-4 pl-0 pr-5" v-if="profileData.info.membership">
          <div class="mcard">
            <membercard :member-id="profileData.info.member_id" :key="updateLoggedUserInfo"></membercard>
          </div>
          <div class="text-center mt-3">
            <a href="javascript:void(0)" @click="refreshCard" class="refreshCardLink"><i class="fas fa-redo" :class="refreshProgress ? 'fa-spin' : ''"></i> Refresh Card</a>
          </div>
        </div>
      </div>
    </div>
    <!--end header-->

    <!-- profile body start-->
    <div class="profile-body mt-4">
      <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
      <custom-alert v-if="displayMissingMemFields==true" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
      <div  class="row">
        <div  class="col-md-6 mb-3">
          <div class="card">
            <div class="card-header">
              <div class="header-title">
                <h3> {{ profileData.info.membership.name }} Information</h3>
              </div>
              <div  class="modal-btn"><!-- Temp edit for shooting -->
                <b-button v-b-modal.modal-center1  @click="editresetvalue"><span class="fa fa-edit"></span> Edit</b-button>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div  class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="form-label">Camp/School Name</label>
                    <span>{{ profileData.campski.company || "N/A" }}</span>

                  </div>
                </div>
                <div  class="col-md-6 col-lg-4 ">
                  <div class="form-group">
                    <label class="form-label">Contact Name (Director)</label>
                    <span>{{ profileData.full_name || "N/A" }}</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="form-label">Membership #</label>
                    <span>{{profileData.confirmation_code || "N/A"}}</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <div class="d-flex">
                      <label class="form-label">Membership Status  <span v-if="profileData.membership_status_info" v-b-tooltip.hover :title="profileData.membership_status_info">
                                             <img  style="width:18px;" v-if="getSiteProps('profile.membershipStatusIcon')" v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" />
                                            </span></label>
                      <span class="text-danger bordernote" v-if="profileData.note" v-b-tooltip.hover :title="profileData.note"><i class="fas fa-clipboard"></i></span>
                    </div>

                    <span class="text-success" v-if="profileData.status_text == 'Current'"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ profileData.status_text }} (Expires {{profileData.valid_thru | formatDob }})</span>
                    <span class="text-warning" v-else-if="profileData.status_text != 'Expired'"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ profileData.status_text || 'N/A' }}</span>
                    <span class="text-warning" v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Expired</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="form-label">Start Date</label>
                    <span>{{ startDate }}</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="form-label">Expiration Date</label>
                    <span>{{ profileData && profileData.membership && profileData.membership.period != 999 ? validThru : "N/A"}}</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="form-label">Website</label>
                    <span>{{profileData.campski.website || "N/A"}}</span>
                  </div>
              </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <div class="header-title">
                  <h3>Additional Information</h3>
                </div>
                <div  class="modal-btn">
                  <b-button v-b-modal.modal-center4 @click="editresetvalue"><span class="fa fa-edit"></span> Edit</b-button>
                </div>
              </div>
              <div class="card-body">
                <div  class="row">
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Closest Airport</label>
                      <span>{{ profileData.campski.closest_airport || "N/A" }}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Number of Instructors</label>
                      <span>{{ profileData.campski.number_of_instructor }}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Type of Camp/School</label>
                      <span>{{ profileData.campski.type || "N/A" }}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4 ">
                    <div class="form-group">
                      <label class="form-label">Months of Operation</label>
                      <span>{{ profileData.campski.month_of_operation }}</span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Disciplines Taught</label>
                      <span><ul><li v-for="(discipline) in discipline_taught_arr" v-bind:key="'discipline'+discipline">{{ discipline || "N/A"}}</li></ul></span>
                    </div>
                  </div>
                  <div  class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Equipment</label>
                      <span><ul><li v-for="(equipment) in equipment_arr" v-bind:key="'equipment'+equipment">{{ equipment || "N/A" }}</li></ul></span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Skill Levels Taught</label>
                      <span>{{ profileData.campski.skill_level_taught || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Boats</label>
                      <span>{{ profileData.campski.boats || "N/A" }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Waterway Type</label>
                      <span>{{ waterWayData }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-label">Waterfront Site Description</label>
                      <span>{{ waterFrontData }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-8">
                    <div class="form-group">
                      <label class="form-label">Additional Information</label>
                      <span>{{ profileData.campski.additional_information }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <div class="header-title">
                  <h3 >Contact Information</h3>
                </div>
                <div  class="modal-btn" v-if="profileData.info.personal.relation===0 || profileData.info.personal.relation===1">
                  <b-button v-b-modal.modal-center2 @click="editresetvalue"><span class="fa fa-edit"></span> Edit</b-button>
                </div>
              </div>
              <div class="card-body">
                <div  class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div  class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">Email</label>
                          <span><a class="email-link" v-bind:href="'mailto:' + profileData.email ">{{ profileData.email || "N/A" }}</a></span>
                        </div>
                      </div>
                      <div  class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">Phone Number</label>
                          <span>{{ profileData.phone_1 || "N/A" }}</span>
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div  class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">Camp/School Site Address (Line 1)</label>
                          <span>{{ primaryContact.address1 || "" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">Address (Line 2)</label>
                          <span>{{ primaryContact.address2 || "" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">City</label>
                          <span>{{ primaryContact.city || "" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">State</label>
                          <span>{{ primaryContact.state_code || primaryContact.state || "" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">Zip Code</label>
                          <span>{{ primaryContact.zip || "" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <hr>
                    <div class="row">
                      <div  class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">Mailing Address (if different)</label>
                          <span>{{ secondaryContact.address1 || "" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">Address (Line 2)</label>
                          <span>{{ secondaryContact.address2 || "" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">City</label>
                          <span>{{ secondaryContact.city || "" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">State</label>
                          <span>{{ secondaryContact.state_code || secondaryContact.state || "" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">Zip Code</label>
                          <span>{{ secondaryContact.zip || "" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <!-- profile body end-->
    <b-modal id="modal-center1" size="lg" ok-title="Update" @ok="updateSchoolInfo" @cancel="editresetvalue" centered
             :title="profileData.info.membership.name+' Information'">
      <div class="form-body">
        <form data-vv-scope="form-1">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Contact Name (Director)<sup class="text-danger">*</sup></label>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="First *" data-vv-as="first name" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-1.first_name') }" class="form-control"
                           v-model="form1.first_name"
                           name="first_name">
                    <div v-show="formErrors.has('form-1.first_name')" class="invalid-feedback">
                      {{ formErrors.first('form-1.first_name') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Last *" data-vv-as="last name" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-1.last_name') }" class="form-control"
                           v-model="form1.last_name"
                           name="last_name">
                    <div v-show="formErrors.has('form-1.last_name')" class="invalid-feedback">
                      {{ formErrors.first('form-1.last_name') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Camp/School Name<sup class="text-danger">*</sup></label>
                <input type="text" v-model="form1.company" data-vv-as="camp/school name" v-validate="'required'"
                       :class="{ 'is-invalid': formErrors.has('form-1.company') }" class="form-control"
                       id="company" name="company"/>
                <div v-show="formErrors.has('form-1.company')" class="invalid-feedback">
                  {{ formErrors.first('form-1.company') }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Website<sup class="text-danger">*</sup></label>
                <input type="text" class="form-control" v-validate="'required|url'"
                       :class="{ 'is-invalid': formErrors.has('form-1.website') }" v-model="form1.website"
                       name="website">
                <div v-show="formErrors.has('form-1.website')" class="invalid-feedback">
                  {{ formErrors.first("form-1.website") }}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal id="modal-center2" size="lg" ok-title="Update" @ok="updateAddressInfo" @cancel="editresetvalue" centered
             :title="'Contact Information'">
      <div class="form-body">
        <form data-vv-scope="form-2">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Email<sup class="text-danger">*</sup></label>
                <input type="text" class="form-control" data-vv-as="email" v-validate="'required|email'"
                       :class="{ 'is-invalid': formErrors.has('form-2.email') }" v-model="form.email"
                       name="email">
                <div v-show="formErrors.has('form-2.email')" class="invalid-feedback">
                  {{ formErrors.first('form-2.email') }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Phone Number<sup class="text-danger">*</sup></label>
                <vue-tel-input v-model="form.phone_1" ref="phone" v-bind="phoneConfig" @validate="validateTelinput" :validCharactersOnly="true"></vue-tel-input>
                <div class="invalid-feedback" v-show="phone_1_error">{{ phone_1_error }}</div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="form-label">Camp/School Site Address <sup class="text-danger">*</sup></label>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Line 1" data-vv-as="Address" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-2.address1') }" class="form-control"
                           v-model="form.primaryContact.address1"
                           name="address1">
                    <div v-show="formErrors.has('form-2.address1')" class="invalid-feedback">
                      {{ formErrors.first("form-2.address1") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Line 2" data-vv-as="line 2" class="form-control"
                           v-model="form.primaryContact.address2"
                           name="address1">
                    <!-- <div v-show="formErrors.has('form-2.address2')" class="invalid-feedback">
                      {{ formErrors.first("form-2.address2") }}
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 d-none">
                  <div class="form-group">
                    <select v-validate="'required'"
                            :class="{ 'is-invalid': formErrors.has('form-2.country') }" class="form-control"
                            name="country"
                            v-model="form.primaryContact.country">
                      <option value="">Select Country</option>
                      <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries"
                              :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">
                        {{ listOfCountry.country }}
                      </option>
                    </select>
                    <div v-show="formErrors.has('form-2.country')" class="invalid-feedback">
                      {{ formErrors.first("form-2.country") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="text" placeholder="City" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-2.city') }" class="form-control"
                           v-model="form.primaryContact.city"
                           name="city">
                    <div v-show="formErrors.has('form-2.city')" class="invalid-feedback">
                      {{ formErrors.first("form-2.city") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <select v-validate="'required'" name="state_code"
                            :class="{ 'is-invalid': formErrors.has('form-2.state_code') }" class="form-control"
                            v-model="form.primaryContact.state_code">
                      <option value="">Select State</option>
                      <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                              :value="listOfState.state_code">{{ listOfState.state }}
                      </option>
                    </select>

                    <div v-show="formErrors.has('form-2.state_code')" class="invalid-feedback">
                      {{ formErrors.first("form-2.state_code") | filterState }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="text" placeholder="Zip Code"
                           v-validate="{ required: true, regex: /^([0-9]+)$/ }"
                           :class="{ 'is-invalid': formErrors.has('form-2.zip') }" class="form-control"
                           v-model="form.primaryContact.zip"
                           maxlength="5"
                           name="zip">
                    <div v-show="formErrors.has('form-2.zip')" class="invalid-feedback">
                      {{ formErrors.first("form-2.zip") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="form-label">Mailing Address <sup class="text-danger"> * </sup> <input  name="same" style="margin-left: 30px" type="checkbox" v-on:change="isSame()" id='same' v-model="form.same" value=true> Same as Site address</label>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Line 1"
                           class="form-control"
                           data-vv-as="Mailing Address" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-2.mailingAddress') }"
                           v-model="form.secondaryContact.address1"
                           name="mailingAddress">
                    <div v-show="formErrors.has('form-2.mailingAddress')" class="invalid-feedback">
                      {{ formErrors.first("form-2.mailingAddress") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Line 2" class="form-control"
                           v-model="form.secondaryContact.address2"
                           name="address1">
                    <!-- <div v-show="formErrors.has('form-2.address2')" class="invalid-feedback">
                      {{ formErrors.first("form-2.address2") }}
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 d-none">
                  <div class="form-group">
                    <select class="form-control" name="country" v-model="form.secondaryContact.country">
                      <option value="">Select Country</option>
                      <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries"
                              :key="'country'+listOfCountry.country"  :selected="listOfCountry.country == 'Bahamas'">
                        {{ listOfCountry.country }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="text" placeholder="City"
                           class="form-control"
                           v-model="form.secondaryContact.city"
                           data-vv-as="City" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-2.city2') }"
                           name="city2">
                    <div v-show="formErrors.has('form-2.city2')" class="invalid-feedback">
                      {{ formErrors.first("form-2.city2") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <select name="state_code2" class="form-control"
                            data-vv-as="State" v-validate="'required'"
                            :class="{ 'is-invalid': formErrors.has('form-2.state_code2') }"
                            v-model="form.secondaryContact.state_code">
                      <option value="">Select State</option>
                      <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                              :value="listOfState.state_code">{{ listOfState.state }}
                      </option>
                    </select>
                    <div v-show="formErrors.has('form-2.state_code2')" class="invalid-feedback">
                      {{ formErrors.first("form-2.state_code2") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="text" placeholder="Zip Code"
                           class="form-control"
                           data-vv-as="Zip" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-2.zip2') }"
                           v-model="form.secondaryContact.zip"
                           maxlength="5"
                           name="zip2">
                    <div v-show="formErrors.has('form-2.zip2')" class="invalid-feedback">
                      {{ formErrors.first("form-2.zip2") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal id="modal-center4" size="lg" ok-title="Update" @ok="updateInfo" @cancel="editresetvalue" centered
             :title="'Additional Information'">
      <div class="form-body">
        <form data-vv-scope="form-4">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Closest Airport</label>
                <input type="text" class="form-control" v-model="form4.closest_airport"
                       name="closest_airport">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group tooltipcss">
                <label class="form-label">Type of Camp/School</label>
                <b-button v-b-tooltip.hover
                          title="Please describe the type of camp or school (e.g. boys, girls, overnight, etc.)."><img
                    v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"/></b-button>
                <input type="text" class="form-control" v-model="form4.type" name="type">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Number of Instructors</label>
                <input type="number" class="form-control" v-validate="{ regex: /^([0-9]+)$/ }"
                       v-model="form4.number_of_instructor"
                       name="number_of_instructor">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Months of Operation <sup class="text-danger">*</sup></label>
                <input type="text" data-vv-as="months of operation" class="form-control"
                       v-validate="'required'"
                       :class="{ 'is-invalid': formErrors.has('form-4.month_of_operation') }"
                       v-model="form4.month_of_operation"
                       name="month_of_operation">
                <div v-show="formErrors.has('form-4.month_of_operation')" class="invalid-feedback">
                  {{ formErrors.first("form-4.month_of_operation") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Disciplines Taught<sup class="text-danger">*</sup></label>

              </div>
              <div class="form-group form-check" :class="{ 'is-invalid': formErrors.has('form-4.equipment') }">
                <div v-for="(dt, index) in disciplineTaught" :key="index">
                  <input v-validate="'required'" name="discipline_taught" data-vv-as="discipline taught" type="checkbox" :id="'dtaught'+index" v-model="form4.discipline_taught" :value="dt.value">
                  <label :for="'dtaught'+index"> {{ dt.text }}</label>
                </div>
                <div >
                </div>


              </div>
              <div v-show="formErrors.has('form-4.discipline_taught')" class="invalid-feedback">
                {{ formErrors.first("form-4.discipline_taught") }}
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Equipment<sup class="text-danger">*</sup></label>
                <div class="form-group form-check"
                     :class="{ 'is-invalid': formErrors.has('form-4.equipment') }">
                  <div v-for="(eq, index) in equipment" :key="index">
                    <input v-validate="'required'" name="equipment" type="checkbox" :id="'equipment'+index" v-model="form4.equipment" :value="eq.value">
                    <label :for="'equipment'+index"> {{ eq.text }}</label>
                  </div>
                </div>
                <div v-show="formErrors.has('form-4.equipment')" class="invalid-feedback">
                  {{ formErrors.first("form-4.equipment") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Skill Levels Taught<sup class="text-danger">*</sup></label>
                <div class="form-group form-check"
                     :class="{ 'is-invalid': formErrors.has('form-4.skill_level_taught') }">
                  <div v-for="(st, index) in skillTaught" :key="index">
                    <input v-validate="'required'" name="skill_level_taught" data-vv-as="skill levels taught" type="checkbox" :id="'skilllevel'+index" v-model="form4.skill_level_taught" :value="st.value">
                    <label :for="'skilllevel'+index"> {{ st.text }}</label>
                  </div>
                </div>
                <div v-show="formErrors.has('form-4.skill_level_taught')" class="invalid-feedback">
                  {{ formErrors.first("form-4.skill_level_taught") }}
                </div>

              </div>
            </div>
            <div class="col-md-6">
              <label class="form-label">Boats<sup class="text-danger">*</sup></label>
              <div class="form-group form-check" :class="{ 'is-invalid': formErrors.has('form-4.boats') }">

                <div v-for="(bt, index) in boats" :key="index">
                  <input v-validate="'required'" name="boats"  type="checkbox" :id="'boats'+index" v-model="form4.boats" :value="bt.value">
                  <label :for="'boats'+index"> {{ bt.text }}</label>
                </div>

              </div>
              <div v-show="formErrors.has('form-4.boats')" class="invalid-feedback">
                {{ formErrors.first("form-4.boats") }}
              </div>
            </div>
            <div class="col-md-6">
              <div class="">
                <label for="clubandteam" class="form-label">Waterway Type <sup
                    class="text-danger">*</sup></label>
              </div>
              <div class="form-group">

                <select class="form-control" data-vv-as="waterway Type" v-validate="'required'"
                        :class="{ 'is-invalid': formErrors.has('form-4.waterway_type') }"
                        v-model="form4.waterway_type" name="waterway_type">
                  <option value="">Select</option>
                  <option value="1">Natural Freshwater Lake</option>
                  <option value="2">Man-made Freshwater Lake</option>
                  <option value="3">River</option>
                  <option value="4">Reservoir</option>
                  <option value="5">Intracoastal</option>
                  <option value="6">Saltwater</option>
                </select>
                <div v-show="formErrors.has('form-4.waterway_type')" class="invalid-feedback">
                  {{ formErrors.first("form-4.waterway_type") }}
                </div>

              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Waterfront Site Description<sup class="text-danger">*</sup></label>
                <select class="form-control" data-vv-as="water front" v-validate="'required'"
                        :class="{ 'is-invalid': formErrors.has('form-4.water_front') }"
                        v-model="form4.water_front" name="water_front">
                  <option value="">Select</option>
                  <option value="1">Private</option>
                  <option value="2">Semi Private</option>
                  <option value="3">Public</option>
                </select>
                <div v-show="formErrors.has('form-4.water_front')" class="invalid-feedback">
                  {{ formErrors.first("form-4.water_front") }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Addittional Information</label>
                <textarea type="text" class="form-control" v-model="form4.additional_information"
                          name="additional_information"></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal id="profile_pic_modal" size="md" ref="modal"  ok-title="Upload photo" centered title="Photo"
             @show="resetModal" @hidden="resetModal" @ok="updateProfilePic">
      <div class="form-body">
        <b-form-file v-model="uploadPicFile" :state="Boolean(uploadPicFile)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." v-on:change="onImageChange" accept=".jpg, .png, .gif"></b-form-file>
        <div class="mt-3">Selected file: <img :src="getPreview()" alt="aa" style="height:177px;width:177px;" /></div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import AutoRenewalButton from "../../../admin/components/AutoRenewalButton";
import CustomAlert from "../../../CustomAlert";
import membercard from "./membercard";
import moment from "moment";
import axios from 'axios';
import { VueTelInput } from 'vue-tel-input';

const validatePhone = (phone, validTelinput, phoneField= '' ) => {
  let phoneText = ( phoneField != '' ? phoneField : "Phone Number");
  if (!phone.length) {
    return { valid: false, error: phoneText+' is required .' };
  }
  if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter valid phone number.' };
  }
  return { valid: true, error: "" };
}

export default {
  name: "myprofile",
  components: {CustomAlert, membercard, AutoRenewalButton /*, Select2*/ , VueTelInput},
  data() {
    return {
      waterWayData:'N/A',
      waterFrontData:'N/A',
      countrylistkey:0,
      phone_1_error: "",
      validTelinput:false,
      phoneConfig: {
        mode: "international",
        defaultCountry: "US",
        styleClasses: this.phone_1_error !== "" ? "is-invalid" : "",
        inputOptions: {
          autocomplete: "on",
          autofocus: false,
          id: "phone_1",
          maxlength: 15,
          name: "phone_number",
          placeholder: "",
          readonly: false,
          required: false,
          showDialCode: false,
          tabindex: 0,
          type: "tel"
        }
      },
      profileData: {
        info:[],
        SafeSport: {
          ActiveStatus: ''
        },
        BGScreening: {
          BackgroundReportPackage: {
            ScreeningStatus: {
              OrderStatus: ''
            }
          }
        }
      },
      primaryContact:[],
      secondaryContact:[],
      discipline_taught_arr:[],
      equipment_arr:[],
      form1:{
        'form_type':'myschool',
        'company': '',
        'first_name': '',
        'last_name': '',
        'website':'',
      },
      form: {
        'form_type':'address',
        "same":false,
        "email": '',
        "primaryContact": {
            "address1": '',
            "address2": '',
            "zip": '',
            "state_code": '',
            "state": '',
            "city": '',
            "country": 'USA',
        },
        "secondaryContact": {
            "address1": '',
            "address2": '',
            "zip": '',
            "state_code": '',
            "state": '',
            "city": '',
            "country": 'USA',
          },
        "phone_1": '',
        "phone_2": '',
      },
      form4:{
        'form_type':'information',
        'closest_airport': '',
        'number_of_instructor': '',
        'type': '',
        'month_of_operation': '',
        'discipline_taught': [],
        'equipment': [],
        'skill_level_taught': [],
        'boats': [],
        'waterway_type': '',
        'water_front': '',
        'additional_information': ''
      },
      disciplineTaught: [
        {text: 'Slalom', value: 'Slalom'},
        {text: 'Trick', value: 'Trick'},
        {text: 'Wakeboard', value: 'Wakeboard'},
        {text: 'Barefoot', value: 'Barefoot'},
        {text: 'Hydrofoil', value: 'Hydrofoil'},
        {text: 'Jump', value: 'Jump'},
        {text: 'Wake Surf', value: 'Wake Surf'},
        {text: 'Show Ski', value: 'Show Ski'},
        {text: 'Adaptive', value: 'Adaptive'},
        {text: 'Kneeboard', value: 'Kneeboard'},
      ],
      equipment: [
        {text: 'Slalom Skis', value: 'Slalom Skis'},
        {text: 'Trick Skis', value: 'Trick Skis'},
        {text: 'Jump Skis', value: 'Jump Skis'},
        {text: 'Beginner Combo Skis', value: 'Beginner Combo Skis'},
        {text: 'Sit Skis (Adaptive)', value: 'Sit Skis (Adaptive)'},
        {text: 'Show Swivel Skis', value: 'Show Swivel Skis'},
        {text: 'Shoe Skis', value: 'Shoe Skis'},
        {text: 'Wake Surf', value: 'Wake Surf'},
        {text: 'Kneeboards', value: 'Kneeboards'},
        {text: 'Wakeboards', value: 'Wakeboards'},
        {text: 'Hydrofoils', value: 'Hydrofoils'},
        {text: 'Students Bring Their Own Equipment', value: 'Students Bring Their Own Equipment'},
      ],
      skillTaught: [
        {text: 'Beginning', value: 'Beginning'},
        {text: 'Intermediate', value: 'Intermediate'},
        {text: 'Advanced', value: 'Advanced'}
      ],
      boats: [
        {text: 'Competition Inboard', value: 'Competition Inboard'},
        {text: 'Competition Outboard', value: 'Competition Outboard'},
        {text: 'Recreational Inboard/Outboard', value: 'Recreational Inboard/Outboard'},
        {text: 'Jet Boat', value: 'Jet Boat'}
      ],
      campArrayData:[
        'equipment',
        'boats',
        'skill_level_taught',
        'discipline_taught'
      ],
      waterWay: {
        '1': 'Natural Freshwater Lake',
        '2': 'Man-made Freshwater Lake',
        '3': 'River',
        '4': 'Reservoir',
        '5': 'Intracoastal',
        '6': 'Saltwater'
      },
      waterFront: {
        '1': 'Private',
        '2': 'Semi Private',
        '3': 'Public',
      },
      alertMessage: "",
      displayAlert: "",
      profileImg:'/dist/img/profile1.png',
      preview:'',
      autoRenew:0,
      autoRenewData:{
        regId:"",
        status:"",
      },
      props_fields: null,
      isExpired:"",
      startDate: '',
      validThru: '',
      uploadPicFile:'',
      profilePic:'',
      digitalInfoView:false,
      checked1: true,
      checked2: false,
      refreshProgress: false,
      stripeToken: null,
      stripeValidate: false,
      page:'campskimyprofile',
    }
  },
  methods:
      {
        validateTelinput(obj){
          this.validTelinput = obj.valid;
          if(obj.valid){
            this.phone_1_error = '';
          }
        },
        isSame(){
          if(this.form.same){
            this.form.secondaryContact = Object.assign({},this.form.primaryContact);
            this.form.secondaryContact.primary = 0;
          }
          else{
            this.form.secondaryContact.address1 = "";
            this.form.secondaryContact.address2 = "";
            this.form.secondaryContact.zip = "";
            this.form.secondaryContact.state_code = "";
            this.form.secondaryContact.state = "";
            this.form.secondaryContact.city = "";
            this.form.secondaryContact.country = "USA";
          }
        },
        resetModal() {
          this.uploadPicFile = '';
          this.preview='';
        },
        updateSchoolInfo(bvModalEvt) {
          // Prevent modal from closing
          bvModalEvt.preventDefault()
          this.$validator.validateAll('form-1').then((result) => {
            if (result) {
              this.updateMemberInfo(this.form1);
              this.$nextTick(() => {
                this.$bvModal.hide('modal-center1')
              });
            }

          });
        },
        updateAddressInfo(bvModalEvt) {
          // Prevent modal from closing
          bvModalEvt.preventDefault();
          this.phone_1_error = "";
          if(this.form.phone_1==="") this.phone_1_error = "Phone Number is required";
          else {
            const validPhone1 = validatePhone(this.form.phone_1, this.validTelinput);
            this.phone_1_error = validPhone1.error;
          }
          this.$validator.validateAll('form-2').then((result) => {
            //this.updateMemberInfo();
            if (result) {
              if(this.phone_1_error !== "") return;
              this.updateMemberInfo(this.form);
              this.$nextTick(() => {
                this.$bvModal.hide('modal-center2')
              });
            }

          });
        },
        updateInfo(bvModalEvt) {
          // Prevent modal from closing
          bvModalEvt.preventDefault()
          this.$validator.validateAll('form-4').then((result) => {
            this.displayAlert = false;

            if (result) {
              this.updateMemberInfo(this.form4);
              this.$nextTick(() => {
                this.$bvModal.hide('modal-center4')
              });
            }

          });
        },
        updateMemberInfo(form){
          this.displayAlert = false;
          axios.post(this.basePath + 'api/member/my_campski_profile', form,{ headers:this.memberHeaders })
              .then(function (response) {
                this.userDatadetails = response.data.data;
                this.alertMessage = "Member Information updated successfully";
                this.displayAlert = true;
                this.loadMembersInfo();
                this.updateLoggedUserInfo += 1;
              }.bind(this))
              .catch(function (error) {
                console.log(error);
              });
        },
        loadMembersInfo(){

          axios.get(this.basePath+"api/member/my_profile",{ headers:this.memberHeaders })
              .then((response)=>{
                let resp  = response.data.data;
                this.profileData = response.data.data;
                this.discipline_taught_arr = this.profileData.campski.discipline_taught.split(",");
                this.equipment_arr = this.profileData.campski.equipment.split(",");
                this.startDate = moment(this.profileData.membership_startdate).format('MM/DD/YYYY');
                this.validThru = moment(this.profileData.membership_endtate).format('MM/DD/YYYY');
                if (this.profileData.campski.waterway_type) {
                  let ww = this.profileData.campski.waterway_type;
                  this.waterWayData = this.waterWay[ww]
                }
                if (this.profileData.campski.water_front) {
                  let wf = this.profileData.campski.water_front;
                  this.waterFrontData = this.waterFront[wf]
                }
                for(let i=0; i<this.profileData.campski_contact_details.length; i++)
                {
                  if(this.profileData.campski_contact_details[i].primary == 1)
                  {
                    this.primaryContact = Object.assign({}, this.profileData.campski_contact_details[i]);
                    this.form.primaryContact = Object.assign({},this.profileData.campski_contact_details[i]);
                  }
                  else
                  {
                    this.secondaryContact = Object.assign({}, this.profileData.campski_contact_details[i]);
                    this.form.secondaryContact = Object.assign({},this.profileData.campski_contact_details[i]);
                  }
                }

                this.form1.company = this.profileData.campski.company;
                this.form1.first_name = this.profileData.personal.first_name;
                this.form1.last_name = this.profileData.personal.last_name;
                this.form1.website = this.profileData.personal.website;

                for (let prop in this.profileData.personal) {
                  if ( Object.prototype.hasOwnProperty.call(this.form, prop) ) {
                    this.form[prop] = this.profileData.personal[prop]
                  }
                }
                for (let prf in this.profileData) {
                  if ( Object.prototype.hasOwnProperty.call(this.form, prf) ) {
                    this.form[prf] = this.profileData[prf]
                  }
                }

                for (let cs in this.profileData.campski) {
                  if ( Object.prototype.hasOwnProperty.call(this.form4, cs) ) {
                    let check = this.campArrayData.includes(cs);
                    if(check){
                      this.form4[cs] = this.profileData.campski[cs].split(',')
                    }else{
                      this.form4[cs] = this.profileData.campski[cs]
                    }

                  }
                }
                this.profileData.info = resp;

                this.autoRenew = resp.auto_renew;
                this.autoRenewData.regId = resp.registration[0].id;
                this.autoRenewData.status = resp.auto_renew;

                this.isExpired = moment(new Date()).isAfter(moment(new Date(this.validThru)));
                this.profileImg = (resp.profile_pic!=null? process.env.VUE_APP_URL+resp.profile_pic:'/dist/img/profile1.png')+'?v='+moment().format('x');


                if(!this.getSiteProps('general.member_profile_addition_info') && this.getSiteProps('general.member_profile_addition_info') != undefined){
                  this.digitalInfoView = false;
                }

              }).catch((error) =>{
            console.log(error);
          });
          this.countrylistkey++;
        },
        editresetvalue(){
          for(let i=0; i<this.profileData.campski_contact_details.length; i++)
          {
            if(this.profileData.campski_contact_details[i].primary == 1)
            {
              this.form.primaryContact = Object.assign({},this.profileData.campski_contact_details[i]);
              if(this.form.primaryContact.state_code==null) {
                this.form.primaryContact.state_code = "";
              }
            }
            else
            {
              this.form.secondaryContact = Object.assign({},this.profileData.campski_contact_details[i]);
              if(this.form.secondaryContact.state_code==null) {
                this.form.secondaryContact.state_code = "";
              }
            }
          }
          this.form1.company = this.profileData.campski.company;
          this.form1.first_name = this.profileData.personal.first_name;
          this.form1.last_name = this.profileData.personal.last_name;
          this.form1.website = this.profileData.personal.website;
          for (let prop in this.profileData.personal) {
            if ( Object.prototype.hasOwnProperty.call(this.form, prop) ) {
              this.form[prop] = this.profileData.personal[prop]
            }
          }
          for (let prf in this.profileData) {
            if ( Object.prototype.hasOwnProperty.call(this.form, prf) ) {
              this.form[prf] = this.profileData[prf]
            }
          }

          for (let cs in this.profileData.campski) {
            if ( Object.prototype.hasOwnProperty.call(this.form4, cs) ) {
              let check = this.campArrayData.includes(cs);
              if(check){
                this.form4[cs] = this.profileData.campski[cs].split(',')
              }else{
                this.form4[cs] = this.profileData.campski[cs]
              }

            }
          }
        },
        resetAlert(){
          this.displayAlert = false;
          this.removeDangerBg();
        },
        updateProfilePic(e) {
          e.preventDefault();
          this.$set(this.memberHeaders, 'content-type', 'multipart/form-data');
          let formData = new FormData();
          formData.append('member_id', this.profileData.info.id);
          formData.append('image', this.profilePic);
          axios.post(this.basePath + 'api/member/profile_pic', formData, {headers: this.memberHeaders})
              .then(function (response) {
                this.alertMessage = "Member profile picture changed successfully";
                this.displayAlert = true;
                var memberInfoData = JSON.parse(localStorage.getItem('member'));
                memberInfoData.profile_pic=response.data.data.profile_pic;
                localStorage.setItem('member',JSON.stringify(memberInfoData));
                this.updateLoggedUserInfo += 1;
                this.loadMembersInfo();
              }.bind(this))
              .catch(function (error) {
                console.log(error);
              });
          this.$nextTick(() => {
            this.$bvModal.hide('profile_pic_modal')
          });
          this.$set(this.memberHeaders, 'content-type', 'application/json');
        },
        onImageChange(e) {
          let file = e.target.files[0];
          this.profilePic = file;
          let reader = new FileReader();
          reader.onloadend = (file) => {
            this.preview = reader.result;
          }
          reader.readAsDataURL(file);
        },
        getPreview(){
          let preview = (this.preview.length > 200) ? this.preview : '/dist/img/avatarimg.png' ;
          return preview;
        },
        isFutureDate(date) {
          const currentDate = new Date();
          return date > currentDate;
        },
        myChangeEvent(val){
          this.UpdateProfile.team_id=val;

        },
        refreshCard(event){
          this.refreshProgress = true;
          let member_id = this.profileData.info.id;
          this.displayAlert = false;
          axios.post(this.basePath+"api/member/refresh-card", { member: member_id })
              .then(res => {
                this.alertMessage = "Member Card updated successfully";
                this.displayAlert = true;
                this.refreshProgress = false;
                this.updateLoggedUserInfo += 1;
                this.loadMembersInfo();
              })
              .catch(err => {
                console.log(err);
                this.refreshProgress = false;
              })
        },

      },
  mounted() {
    this.loadMembersInfo();
  },
  filters: {
    filterState: function(value){
      if (!value) return '';
      return value.replace("state_code", "state");
    }
  }
}
</script>

<style scoped>
.hovereffect {
  position: relative;
}
.avatar{
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}
.overlay {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.hovereffect:hover .avatar {
  opacity: 0.3;
}
.hovereffect:hover .overlay {
  opacity: 1;
}
.text {
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  padding: 5px;
}
.group_tag_title strong{
  font-size: 16px;
}
</style>
